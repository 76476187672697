<template>
  <span class="v-exit" @click="exit">返回</span>
</template>

<script>
export default {
  name: "VExit",
  methods: {
    exit() {
      this.$router.replace({
        path: "/project"
      })
    }
  }
}
</script>

<style scoped>
.v-exit {
  font-size: 16px;
  color: #E8F9FF;
  cursor: pointer;
  margin-right: 12px;
}
</style>