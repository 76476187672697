<template>
  <div class="v-card-weather">
    <div class="vc-list">
      <span class="img"><img v-if="screenData.image" :src="imgObj[screenData.image]" alt=""></span>
      <div>
        <p class="label">{{screenData.city}} {{ screenData.area }}</p>
        <p class="value">{{screenData.temprature}} {{screenData.tianqi}}</p>
      </div>
    </div>
    <div class="vc-list">
      <p class="label">空气质量</p>
      <p class="tag">{{screenData.air}}</p>
    </div>
    <div class="vc-list">
      <p class="label">风力</p>
      <p>{{screenData.wind}}</p>
    </div>
  </div>
</template>

<script lang="js">
import { mapState } from 'vuex';

export default {
  name: 'VCard2',
  props: {// 切换项目，获取天气
    propsData: {
      type: String, 
      default: ""
    }
  },
  watch: {
    propsData: {
      handler() {
        this.projectCode = this.propsData;
        this.initData()
      }
    }
  },
  data() {
    return {
      screenData: {},
      projectCode: "",
      imgObj: {
        qing: require("@/assets/images/weather/qing.png"),
        dalei: require("@/assets/images/weather/dalei.png"),
        dawu: require("@/assets/images/weather/dawu.png"),
        duoyun: require("@/assets/images/weather/duoyun.png"),
        bingbao: require("@/assets/images/weather/bingbao.png"),
        shachen: require("@/assets/images/weather/shachen.png"),
        xue: require("@/assets/images/weather/xue.png"),
        yin: require("@/assets/images/weather/yin.png"),
        yu: require("@/assets/images/weather/yu.png"),
        feng: require("@/assets/images/weather/feng.png"),
      }
    }
  },
  computed: {
    ...mapState(["projectCurrentRowData"])
  },
  mounted() {
    const currentData = this.projectCurrentRowData || {};
    this.projectCode = currentData.projectCode;
    this.initData()
  },
  methods: {
    initData() {
      this.request.screenWeather({ project: this.projectCode }).then(res => {
        if (res.code == 0) {
          this.screenData = Object.assign({}, res.data);
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.v-card-weather {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 321.33px;
  padding-right: 12px;
  box-sizing: border-box;
  >.vc-list {
    flex: 1;
    position: relative;
    .img {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      left: -24px;
      top: 0;
      bottom: 0;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    
    p {
      text-align: center;
      line-height: 24px;
      color: #FFFFFF;
    }
    p.label {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 12px;
      letter-spacing: 0em;
      color: rgba(255, 255, 255, 0.6);
    }
    p.value {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0em;
      color: #FFFFFF;
      &.tag {
        color: rgba(106, 255, 213, 0.9412);
      }
    }
  }
}
</style>
